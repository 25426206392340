
import {defineComponent, onMounted, ref} from 'vue'
import {ChartCode, ChartType} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Chart from "@/views/chart/Chart.vue";
import BaseClientSelect from "@/views/company/BaseClientSelect.vue";

export default defineComponent({
  name: "Reserve",
  components: {BaseClientSelect, Chart},
  setup() {
    const clientId = ref('');
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true
      setCurrentPageBreadcrumbsWithParams("Reserves Dashboard", [])
    })
    return {
      mounted,
      clientId,
      ChartCode,
      ChartType,
    }
  }
})
